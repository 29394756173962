import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import Login from './pages/Login';
import './App.css';
import Layout from './pages/Layout';
import Dashboard from './pages/Dashboard';
import Findings from './pages/Findings';
import FindingDetails from "./pages/FindingDetails";
import ChatCard from "./pages/ChatCard";

function App() {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  // Scroll to the top of the page on path change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Show loading screen for 1 second before displaying content
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="App">
        <Loader />
      </div>
    );
  }

  return (
    <div className="App">
    <Routes>
      {/* Public route */}
      <Route path="/" element={<Login />} />

      {/* Layout route with nested children */}
      <Route path="/dashboard" element={<Layout />}>
        {/* Nested routes inside the Layout */}
        <Route index element={<Dashboard />} />
        <Route path="findings" element={<Findings />} />
        <Route path="details" element={<FindingDetails />} />
        <Route path="chat" element={<ChatCard />} />

        {/* Uncomment and define additional routes as needed */}
        {/* <Route path="calendar" element={<Calendar />} />
        <Route path="profile" element={<Profile />} />
        <Route path="forms/form-elements" element={<FormElements />} />
        <Route path="tables" element={<Tables />} />
        <Route path="settings" element={<Settings />} /> */}
      </Route>
    </Routes>
  </div>
  );
}

export default App;
