import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const VulnerabilitySpiderChart = ({ finding: initialFinding ,color}) => {
  // Initialize state with the initial finding data
  const [finding, setFinding] = useState(initialFinding);

  // Optionally, useEffect to update the state when new finding is passed as a prop
  useEffect(() => {
    if (initialFinding) {
      setFinding(initialFinding);
    }
  }, [initialFinding]);

  // Validate the finding object and ensure values are correctly handled
  const cvssScore = Math.min(finding?.cvss_score || 0, 10);  // CVSS Score should not exceed 10
  const affectedComponents = Math.min(finding?.affected_components?.length || 0, 10);  // Cap at 10 if greater than 10
  const occurrences = finding?.occurrences || 0;
  const reproduceComp = Math.min(finding?.reproduce_complexity || 0, 10);  // Scale 0-10, 0 being easy, 10 being hard
  const remediationComp = Math.min(finding?.remediation_complexity || 0, 10);  // Scale 0-10, 0 being easy, 10 being hard


  // Spider chart data and options
  const spiderChartOptions = {
    series: [
      {
        name: "Value",
        data: [
          cvssScore,  // CVSS Score
          affectedComponents,  // Affected Components
          occurrences,  // Number of Occurrences
          reproduceComp,  // Reproduce Complexity
          remediationComp,  // Remediation Complexity
        ],
      },
    ],
    chart: {
      height: 350,
      type: "radar",
    },
    xaxis: {
      categories: [
        "CVSS Score",
        "Affected Components",
        "Occurrences",
        "Reproduce Complexity",
        "Remediation Complexity",
      ],
    },
    yaxis: {
      min: 0,
      max: 10,
      tickAmount: 5,
    },
    // colors: ["#FF5733"], // Custom line color for the radar
  fill: {
    opacity: 0.4, // Transparency for the filled area
    colors: [color], // Fill color for the radar area
  },
  stroke: {
    show: true,
    colors: [color], // Border color for the radar area
    width: 2, // Thickness of the border
  },

  markers: {
    size: 5, // Size of the dot
    colors: [color], // Dot color
    strokeColors: color, // Border color of the dot
    strokeWidth: 2, // Thickness of the dot border
    shape: "circle", // Shape of the marker: 'circle', 'square', 'triangle', etc.
    hover: {
      size: 7, // Size when hovering
    },
  },

  };

  // Return a loading state or the chart component
  if (!finding) {
    return <p>No finding selected.</p>;
  }

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-5">
      <div className="mt-4 flex items-end justify-between">
        <div>
          <h4 className="text-title-md font-bold text-black dark:text-white">
            Vulnerability Metrics
          </h4>
        </div>
      </div>

      <div id="spiderChart">
        <ReactApexChart
          options={spiderChartOptions}
          series={spiderChartOptions.series}
          type="radar"
          height={350}
         
        />
      </div>
  {/* Metric descriptions */}
  <div >
      <h5 className="text-title-sm font-bold text-black dark:text-white">
        Metric Descriptions:
      </h5>
      <ul className="mt-2 list-disc pl-5 text-black dark:text-white">
        <li>
          <strong>CVSS Score:</strong> The severity of the vulnerability on a scale of 0-10.
        </li>
        <li>
          <strong>Affected Components:</strong> The number of components impacted by the vulnerability.
        </li>
        <li>
          <strong>Occurrences:</strong> How many times this vulnerability occurs in the environment.
        </li>
        <li>
          <strong>Reproduce Complexity:</strong> The difficulty of reproducing the vulnerability, scaled from 0 (easy) to 10 (hard).
        </li>
        <li>
          <strong>Remediation Complexity:</strong> The difficulty of fixing the vulnerability, scaled from 0 (easy) to 10 (hard).
        </li>
      </ul>
    </div>
  </div>
  );
};

export default VulnerabilitySpiderChart;
