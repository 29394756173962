import React, { useMemo } from 'react';
import { Link ,useNavigate } from 'react-router-dom';

const RecentFindingsCard = (props) => {

  // Memoize the initialization of the data array, if necessary
  const memoizedData = useMemo(() => props.data, [props.data]);
  const navigate = useNavigate();

  // Define category-to-color mapping for better readability
  const categoryColors = {
    low: '#2fde17',
    medium: '#e9df16',
    high: '#e9b116',
    critical: '#f31010',
  };

  const handleSelectFinding = (findingID) => {
    navigate("/dashboard/details", { state: findingID });
  };
  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-7">
            <div className="mb-3 justify-between gap-4 sm:flex">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-white">
          Recent Findings
          </h5>
        </div>
   
      </div>



      <div>


      {memoizedData.slice(0, 9).map((item) => (

<div
className="flex min-w-47.5 mt-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-all"
key={item.id}
onClick={() => handleSelectFinding(item.id)}
role="button"
aria-label={`View details for ${item.title}`}
tabIndex={0}
>
{/* Circle with category color */}
<span
  className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border"
  style={{ borderColor: categoryColors[item.category] }}
>
  <span
    className="block h-2.5 w-full max-w-2.5 rounded-full"
    style={{ backgroundColor: categoryColors[item.category] }}
  ></span>
</span>

{/* Title of the finding */}
<div className="w-full">
  <p className="font-semibold text-black dark:text-white">
    {item.title}
  </p>
</div>
</div>
     
              ))}


      </div>
      <Link
              to="findings"
              className="mt-5 w-full inline-flex items-center justify-center rounded-md bg-sky-500/100 py-1.5 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
            >
             All Findings
            </Link>
    </div>
  );
};

export default RecentFindingsCard;
