import React ,{ useMemo } from "react";
import { Link } from 'react-router-dom';
import FindingsOverviewChart from '../components/Dashboard/Charts/FindingsOverviewChart';
import RecentFindingsCard from '../components/Dashboard/FindingsCard/RecentFindingsCard';
import OverallFindingsChart from '../components/Dashboard/Charts/OverallFindingsChart';
import Breadcrumb from '../components/Dashboard/Breadcrumbs/Breadcrumb';
import { findingsData } from '../data';


function Dashboard() {

    const data = useMemo(() => findingsData, []);
    
      const processedData = useMemo(() => {
        const categories = ["low", "medium", "high", "critical"];
        const result = {};
    
        // Initialize empty arrays for each category
        categories.forEach(category => {
          result[category] = {};
        });
    
        // Populate data for each category by date
        data.forEach(item => {
          const { date, category } = item;
          if (!result[category][date]) {
            result[category][date] = 0;
          }
          result[category][date] += 1;
        });
    
        // Format data as array of objects { date, value } for each category
        const formattedData = {};
        categories.forEach(category => {
          formattedData[category] = Object.entries(result[category]).map(
            ([date, value]) => ({ date, value })
          );
        });
    
        return formattedData;
      }, [data]);
  return (
    <>
     <Breadcrumb pageName="Dashboard" />
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-4 xl:grid-cols-4 2xl:gap-7.5">
    <FindingsOverviewChart category="Low" value={processedData.low} />
    <FindingsOverviewChart category="Medium" value={processedData.medium} />
    <FindingsOverviewChart category="High" value={processedData.high} />
    <FindingsOverviewChart category="Critical" value={processedData.critical} />
       

    </div>

    <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
      <RecentFindingsCard data={data}/>
      <OverallFindingsChart data={processedData}/>

    </div>
  </>
  )
}

export default Dashboard
