import React, { useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";

function CategorizedFindings(props) {
    const [filter, setFilter] = useState("All");
    const navigate = useNavigate();

  // Memoize the initialization of the data array
  const memoizedData = useMemo(() => {
    if (filter === 'All') {
      return props.data; // Show all data if filter is 'all'
    }
    return props.data.filter(item => item.category === filter.toLowerCase()); // Filter by category
  }, [filter, props.data]);

  // Define category-to-color mapping for better readability
  const categoryColors = {
    all: '#3b71ca',
    low: '#2fde17',
    medium: '#e9df16',
    high: '#e9b116',
    critical: '#f31010',
  };

  const handleSelectFinding = (finding) => {
    navigate("/dashboard/details", { state: finding });
  };
  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
      <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
        <div className="mb-3 justify-between gap-4 sm:flex">
          <div>
            <h5 className="text-xl font-semibold text-black dark:text-white">
              {filter} Findings
            </h5>
          </div>
        </div>

        <div className="flex w-full max-w-45 justify-end">
          <div className="inline-flex items-center rounded-md bg-whiter p-1.5 dark:bg-meta-4">
            {/* Dropdown for selecting category */}
            <select
              className="rounded py-1 px-3 text-xs font-medium text-black dark:text-white bg-white dark:bg-boxdark border border-stroke dark:border-strokedark"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
              <option value="Critical">Critical</option>
            </select>
          </div>
        </div>
      </div>

      <div
        style={{
          maxHeight: 'calc(100vh - 270px)', // Set the height as needed
          overflowY: 'auto',  // Enable vertical scrolling
        }}
      >
        {memoizedData.map((item) => (
     <div
     className="flex min-w-47.5 mt-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-all"
     key={item.id}
     onClick={() => handleSelectFinding(item.id)}
     role="button"
     aria-label={`View details for ${item.title}`}
     tabIndex={0}
   >
     {/* Circle with category color */}
     <span
       className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border"
       style={{ borderColor: categoryColors[item.category] }}
     >
       <span
         className="block h-2.5 w-full max-w-2.5 rounded-full"
         style={{ backgroundColor: categoryColors[item.category] }}
       ></span>
     </span>
   
     {/* Title of the finding */}
     <div className="w-full">
       <p className="font-semibold text-black dark:text-white">
         {item.title}
       </p>
     </div>
   </div>
        ))}
      </div>
    </div>
  );
}

export default CategorizedFindings;
