import React, { useRef, useEffect } from 'react';
import ApexCharts from 'apexcharts';

function FindingsOverviewChart({value,category}) {
  const getCategoryColor = (category) => {
    switch (category.toLowerCase()) {
      case 'low':
        return '#2fde17'; // Green for low
      case 'medium':
        return '#e9df16'; // Yellow for medium
      case 'high':
        return '#e9b116'; // Orange for high
      case 'critical':
        return '#f31010'; // Red for critical
      default:
        return '#cccccc'; // Default gray color
    }
};

  const chartRef1 = useRef(null);

  const color = getCategoryColor(category);
  // Define options as a function or object, as per your current setup
  


  useEffect(() => {
    
  const options = (type, height) => ({
    chart: {
        height: height,
        width: '100%',
        type: type,
        sparkline: {
          enabled: true
        },
        toolbar: {
          show: false,
         },
      },
      grid: {
          show: false,
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0    
          }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
          show: false,
      },
      series: [
      {
          name: "Findings",
          data: value.map(item => ({ x: item.date, y: item.value }))
      }
      ],    
      fill: {
        colors: color,
      },
      stroke:{
          colors: [color],
          width: 3
      },    
      yaxis: {
          show: false,        
      }, 
      xaxis: {
        show: false,
        labels: {
            show: false,
        },   
        axisBorder: {
          show: false,        
        },   
        tooltip: {
            enabled: false,
        }
      },
});

    if (chartRef1.current) {
      // Initialize ApexCharts on the refs
      const chart1 = new ApexCharts(chartRef1.current, options("area", '51px'));
 
      // Render the charts
      chart1.render();


      // Cleanup on unmount
      return () => {
        chart1.destroy();

      };
    }
  }, [value,color]);
  return (
    <div className="rounded-sm border border-stroke bg-white py-5 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
    <div className="mt-4 flex items-end justify-between">
      <div>
        <h4 className="text-title-md font-bold text-black dark:text-white">
        {value.reduce((sum, item) => sum + item.value, 0)}  {category}
        </h4>
        <span className="text-sm font-small">Findings</span>
      </div>

    </div>
    <div ref={chartRef1}></div>
  </div>
)
}
export default FindingsOverviewChart