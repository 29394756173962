import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const OverallFindingsCard = (props) => {
    console.log(props.data.low.reduce((sum, item) => sum + item.value, 0));
  const [state] = useState({
    series: [
      props.data.low.reduce((sum, item) => sum + item.value, 0),
      props.data.medium.reduce((sum, item) => sum + item.value, 0),
      props.data.high.reduce((sum, item) => sum + item.value, 0),
      props.data.critical.reduce((sum, item) => sum + item.value, 0),
    ],
  });

  const chartOptions = {
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'donut',
    },
    colors: ['#2fde17', '#e9df16', '#e9b116', '#f31010'],
    labels: ['Low', 'Medium', 'High', 'Critical'],
    legend: {
      show: false,
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 380,
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total Findings',
                formatter: () => `${state.series.reduce((sum, val) => sum + val, 0)}`,
                color: '#333',
                fontSize: '18px',
              },
            },
          },
        },
      }
  };

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-5">
      {/* Header */}
      <div className="mb-3 flex justify-between gap-4 sm:flex">
        <h5 className="text-xl font-semibold text-black dark:text-white">
          Overall Findings
        </h5>
      </div>

      {/* Donut Chart */}
      <div className="mb-2">
        <div id="chartThree" className="mx-auto flex justify-center">
          <ReactApexChart
            options={chartOptions}
            series={state.series}
            type="donut"
          />
        </div>
      </div>

      {/* Legend */}
      <div className="-mx-8 flex flex-wrap items-center justify-center gap-y-3">
        {[
                
          { label: 'Low', color: 'bg-[#2fde17]' },
          { label: 'Medium', color: 'bg-[#e9df16]' },
          { label: 'High', color: 'bg-[#e9b116]' },
          { label: 'Critical', color: 'bg-[#f31010]' },
        ].map((item, index) => (
          <div key={index} className="w-full sm:w-1/2 px-8">
            <div className="flex w-full items-center">
              <span
                className={`mr-2 block h-3 w-full max-w-3 rounded-full ${item.color}`}
              ></span>
              <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
                <span>{item.label}</span>
                <span>{state.series[index]}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OverallFindingsCard;
