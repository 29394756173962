import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../components/Dashboard/Breadcrumbs/Breadcrumb";
import DetailedFindingsChart from "../components/Dashboard/Charts/DetailedFindingsChart";
import { findingsData } from "../data";
import Find from '../images/image.png';

const FindingDetails = () => {
  const { state: initialFindingID } = useLocation();


  // State for selected finding ID
const [findingID, setFindingID] = useState(() => {
  if (initialFindingID) {
    return Number(initialFindingID);
  } else {
    return findingsData[0]?.id || null;
  }});

  // State for selected finding details
  const [finding, setFinding] = useState(() =>
    findingsData.find((f) => f.id === findingID) || null
  );

  // Update `finding` whenever `findingID` changes
  useEffect(() => {
  
    const selectedFinding = findingsData.find((f) => f.id === Number(findingID));
    setFinding(selectedFinding || null);
  }, [findingID]);

  // If finding is not found, display a message
  if (!finding) {
    return (
      <div className="text-center mt-10">
        <p className="text-lg font-semibold text-red-500">
          No finding details available.
        </p>
      </div>
    );
  }

  const categoryColors = {
    all: "#3b71ca",
    low: "#2fde17",
    medium: "#e9df16",
    high: "#e9b116",
    critical: "#f31010",
  };

  const handleSelectChange = (e) => {
    setFindingID(Number(e.target.value)); // Convert to number
  };

  return (
    <>
      <Breadcrumb pageName="Detailed Findings" />
      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        {/* Finding Details Section */}
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-7">
    

          <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
            <div className="mb-3 justify-between gap-4 sm:flex"></div>

            <div className="flex w-full max-w-45 justify-end">
              <div className="inline-flex items-center rounded-md bg-whiter p-1.5 dark:bg-meta-4">
                {/* Dropdown for selecting finding */}
                <select
                className="rounded py-1 px-3 text-xs font-medium text-black dark:text-white bg-white dark:bg-boxdark border border-stroke dark:border-strokedark"
                value={findingID}
                onChange={handleSelectChange}
              >
                {findingsData.map((finding) => (
                  <option key={finding.id} value={finding.id}>
                    {finding.title}
                  </option>
                ))}
              </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-7.5 p-4 sm:p-6 xl:p-9"
            style={{
                maxHeight: 'calc(100vh + 70px)', // Set the height as needed
                overflowY: 'auto',  // Enable vertical scrolling
            }}>
            <h3 className="mb-5 text-title-md2 font-bold text-black dark:text-white">
              {finding.title}
            </h3>
            <p className="font-medium text-black dark:text-white">
              {finding.description}
            </p>

            <div>
              <h4 className="text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
                CVSS Score : {finding.cvss_score} ({finding.category})
              </h4>
              <h4 className="text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
                Status : {finding.status}
              </h4>
              <h4 className="text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
                Occurrences : {finding.occurrences}
              </h4>
            </div>

            <div>
              <h4 className="text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
                Affected Components
              </h4>
              <ul className="text-black dark:text-white list-disc pl-5">
                {finding.affected_components.map((component, index) => (
                  <li key={index}>{component}</li>
                ))}
              </ul>
            </div>

            <div>
              <h4 className="text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
                Impact
              </h4>
              <p className="font-medium text-black dark:text-white">
                {finding.impact}
              </p>
            </div>

            <div>
              <h4 className="text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
                Suggested Remediation
              </h4>
              <p className="font-medium text-black dark:text-white">
                {finding.suggested_remediation}
              </p>
            </div>

            <div>
              <h4 className="text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
                Steps to Reproduce
              </h4>
              <ul className="text-black dark:text-white list-disc pl-5">
                {finding.steps_to_reproduce.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Detailed Findings Chart */}
        <DetailedFindingsChart finding={finding} color={categoryColors[finding.category]}/>
      </div>

      {/* POC Section */}
      {finding.poc && finding.poc.images?.length > 0 && (
  <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-12">
      <div className="flex flex-col gap-7.5 p-4 sm:p-6 xl:p-9">
        <h4 className="text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
          POC
        </h4>
        <div className="flex flex-col gap-4">
          {finding.poc.images.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={Find} // Assuming `src` contains the image URL
                alt={image.description || `POC Image ${index}`}
                className="h-full w-full rounded object-cover object-center"
              />
              {image.description && (
                <p className="mt-2 text-sm font-medium text-black dark:text-white">
                  {image.description}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)}

    </>
  );
};

export default FindingDetails;

