export const findingsData = [
    {
        "id": 1,
        "title": "SQL Injection in Login Form",
        "description": "The login form is vulnerable to SQL injection, allowing attackers to bypass authentication and access sensitive data.",
        "cvss_score": 9.1,
        "category": "critical",
        "date": "2024-11-01",
        "status": "open",
        "occurrences": 12,
        "affected_components": ["Login Form", "Database"],
        "impact": "Potential full compromise of the database and unauthorized access to sensitive user data.",
        "suggested_remediation": "Implement prepared statements or parameterized queries to prevent SQL injection attacks.",
        "steps_to_reproduce": [
            "Navigate to the login page of the application.",
            "Enter `' OR '1'='1` in both the username and password fields.",
            "Submit the form and observe that authentication is bypassed, granting access."
        ],
        "poc": {
            "images": [
                {
                    "src": "path/to/sql_injection_attempt.png",
                    "description": "SQL injection string entered into the login form fields."
                },
                {
                    "src": "path/to/sql_injection_result.png",
                    "description": "Successful login bypass using SQL injection."
                }
            ]
        },
        "score": 19,
        "reproduce_complexity": 6,
        "remediation_complexity": 8
    },
    {
        "id": 2,
        "title": "Cross-Site Scripting (XSS) in Search Field",
        "description": "The search input field does not properly sanitize user input, which could allow for a stored XSS attack.",
        "cvss_score": 7.2,
        "category": "high",
        "date": "2024-10-25",
        "status": "open",
        "occurrences": 8,
        "affected_components": ["Search Functionality", "Frontend Rendering"],
        "impact": "Attackers could inject malicious scripts, potentially affecting all users who view the infected search results.",
        "suggested_remediation": "Sanitize user input and use a security library like DOMPurify to handle HTML content.",
        "steps_to_reproduce": [
            "Open the application and locate the search input field.",
            "Input `<script>alert('XSS')</script>` in the search field.",
            "Submit the search and observe that the alert is displayed, confirming XSS vulnerability."
        ],
        "poc": {
            "images": [
                {
                    "src": "path/to/xss_alert.png",
                    "description": "Alert triggered by injected XSS payload in the search field."
                },
                {
                    "src": "path/to/xss_source_code.png",
                    "description": "Browser developer tools showing the injected script in the page source."
                }
            ]
        },
        "score": 16,
        "reproduce_complexity": 3,
        "remediation_complexity": 5
    },
    {
        "id": 3,
        "title": "Sensitive Information Exposure in Error Messages",
        "description": "Detailed error messages expose sensitive information such as database structure and server paths.",
        "cvss_score": 5.3,
        "category": "medium",
        "date": "2024-10-20",
        "status": "closed",
        "occurrences": 5,
        "affected_components": ["Error Handling", "Backend"],
        "impact": "Attackers could use the exposed information to craft more targeted attacks.",
        "suggested_remediation": "Configure the server to return generic error messages and log detailed messages for debugging.",
        "steps_to_reproduce": [
            "Trigger an error condition (e.g., submit a request with invalid parameters).",
            "Observe the error response returned by the server.",
            "Notice the detailed error message revealing sensitive information, such as stack traces or database queries."
        ],
        "poc": {
            "images": [
                {
                    "src": "path/to/error_stack_trace.png",
                    "description": "Error message displaying stack trace and database details."
                }
            ]
        },
        "score": 12,
        "reproduce_complexity": 2,
        "remediation_complexity": 2
    },
    {
        "id": 4,
        "title": "Weak Password Policy",
        "description": "The application allows users to set weak passwords, making accounts vulnerable to brute force attacks.",
        "cvss_score": 6.4,
        "category": "medium",
        "date": "2024-11-05",
        "status": "open",
        "occurrences": 10,
        "affected_components": ["Authentication System"],
        "impact": "Accounts can be compromised through brute force or dictionary attacks.",
        "suggested_remediation": "Enforce strong password requirements, including minimum length, complexity, and character variety.",
        "steps_to_reproduce": [
            "Register a new account with a password like '1234'.",
            "Observe that the application accepts the weak password without validation."
        ],
        "poc": {
            "images": [
                {
                    "src": "path/to/weak_password_acceptance.png",
                    "description": "Registration form allowing the submission of a weak password."
                }
            ]
        },
        "score": 14,
        "reproduce_complexity": 1,
        "remediation_complexity": 4
    }
    // Add similar image samples for other findings as needed
];
