import { useEffect, useState } from 'react';


function useLocalStorage(keyName, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get value from localStorage
      const item = window.localStorage.getItem(keyName);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error accessing localStorage', error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      // Save the updated value to localStorage
      window.localStorage.setItem(keyName, JSON.stringify(storedValue));
    } catch (error) {
      console.error('Error saving to localStorage', error);
    }
  }, [keyName, storedValue]);

  const updateValue = (value) => {
    setStoredValue((prevValue) =>
      typeof value === 'function' ? value(prevValue) : value
    );
  };

  return [storedValue, updateValue];
}

export default useLocalStorage;
