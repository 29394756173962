import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

// Example options for the chart
const options = {
  colors: [ '#80CAEE'],
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'bar',
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '25%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '25%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: ['Low', 'Medium', 'High', 'Critical'], // Categories for findings
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontFamily: 'Satoshi',
    fontWeight: 500,
    fontSize: '14px',
    markers: {
      radius: 99,
    },
  },
  fill: {
    opacity: 1,
  },
};

const ChartTwo = ({ findingsData }) => {
  // Initialize state with default values
  const [state, setState] = useState({
    series: [
      {
        name: 'Findings',
        data: [0, 0, 0, 0], // Default data for Low, Medium, High, Critical findings
      },
    ],
  });

  useEffect(() => {
    // Function to classify the findings into Low, Medium, High, and Critical based on the severity or CVSS score
    const classifyFindings = (data) => {
      const low = data.filter((finding) => finding.cvss_score <= 4.9).length;
      const medium = data.filter((finding) => finding.cvss_score >= 5 && finding.cvss_score <= 6.9).length;
      const high = data.filter((finding) => finding.cvss_score >= 7 && finding.cvss_score <= 8.9).length;
      const critical = data.filter((finding) => finding.cvss_score >= 9).length; // Critical findings

      return { low, medium, high, critical };
    };

    // Classify the findings data
    const { low, medium, high, critical } = classifyFindings(findingsData);

    // Update the state with classified data
    setState({
      series: [
        {
          name: 'Findings',
          data: [low, medium, high, critical], // Update data to include Critical
        },
      ],
    });
  }, [findingsData]); // Re-run the effect when the data changes

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4">
      <div className="mb-4 justify-between gap-4 sm:flex">
        <div>
          <h4 className="text-xl font-semibold text-black dark:text-white">
            Findings Overview
          </h4>
        </div>
      </div>

      <div>
        <div id="chartTwo" className="-ml-5 -mb-9">
          <ReactApexChart
            options={options}
            series={state.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartTwo;
