import React , { useMemo }  from 'react'
import Breadcrumb from '../components/Dashboard/Breadcrumbs/Breadcrumb';
import CategorizedFindings from '../components/Dashboard/FindingsCard/CategorizedFindings';
import { findingsData } from '../data';
import ChartTwo from '../components/Dashboard/Charts/ChartTwo';

function Findings() {
    const data = useMemo(() => findingsData, []);
  return (
    <>
     <Breadcrumb pageName="Findings" />

    <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <CategorizedFindings data={data} />
        <ChartTwo findingsData={data}/>
        {/* <FindingsOverviewChart data={data} /> */}
      {/* <RecentFindingsCard data={data}/>
      <OverallFindingsCard data={processedData}/> */}
    </div>
  </>
  )
}

export default Findings